import React from "react";
import ContactForm from "./contactForm";

const Contacts = () => {
  return (
    <div>
      <h1>Contact US</h1>
      <ContactForm />
    </div>
  );
};

export default Contacts;
