import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NavBar from "./components/navbar";
import "./App.css";
import AboutUs from "./components/aboutUs";
import Restaurant from "./components/restaurant";
import Home from "./components/home";
import Contacts from "./components/contacts";
import Gallery from "./components/gallery";
import BarGallery from "./components/barGallery";

function App() {
  return (
    <React.Fragment>
      <NavBar />

      <main className="container">
        <div className="content">
          <Switch>
            <Route path="/contacts" component={Contacts} />
            <Route path="/aboutUs" component={AboutUs} />
            <Route path="/bar" component={BarGallery} />
            <Route path="/restaurant" component={Restaurant} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/home" component={Home} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="/" />
          </Switch>
        </div>
      </main>
    </React.Fragment>
  );
}

export default App;
