import React from "react";
import { NavLink, Link } from "react-router-dom";
import "bootstrap/js/src/collapse.js";

const NavBar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: "#48c3f7" }}
    >
      <Link className="navbar-brand" to="/">
        7 Days Hotel
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <NavLink className="nav-item nav-link " to="/">
            Home
          </NavLink>
          <NavLink className="nav-item nav-link" to="/aboutUs">
            About Us
          </NavLink>
          <NavLink className="nav-item nav-link" to="/restaurant">
            Restaurant
          </NavLink>
          <NavLink className="nav-item nav-link" to="/bar">
            Bar
          </NavLink>
          <NavLink className="nav-item nav-link" to="/gallery">
            Gallery
          </NavLink>
          <NavLink className="nav-item nav-link" to="/contacts">
            Contacts
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
