import React from "react";
import Collapsible from "react-collapsible";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FoodGallery from "./foodGallery";
import RoomGallery from "./roomGallery";
import BarGallery from "./barGallery";

class Gallery extends React.Component {
  render() {
    const trigger = (name) => (
      <div style={{ backgroundColor: "#B7DBDC", opacity: "0.8" }}>
        <h1>
          {name} {<ArrowDownwardIcon />}
        </h1>
      </div>
    );
    return (
      <>
        <div className="clickable">
          <Collapsible trigger={trigger("Room")}>
            <RoomGallery />
          </Collapsible>
        </div>
        <div className="clickable">
          <Collapsible trigger={trigger("Food")}>
            <FoodGallery />
          </Collapsible>
        </div>
        <div className="clickable">
          <Collapsible trigger={trigger("Bar")}>
            <BarGallery />
          </Collapsible>
        </div>
      </>
    );
  }
}

export default Gallery;
