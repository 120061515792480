import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast, Zoom } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  handleSubmit(e) {
    e.preventDefault();

    const { name, email, subject, message } = this.state;

    let templateParams = {
      from_name: email,
      to_name: "<xzhuang1_ualberta_ca>",
      subject: subject,
      message_html: message,
    };

    emailjs
      .send(
        "default_service",
        "template_u9FZdC84",
        templateParams,
        "user_ZTFbA94hu9j16RtNaTPmJ"
      )
      .then(
        function (response) {
          toast.success("Success!", { transition: Zoom });
        },
        function (error) {
          toast.error("Something goes wrong. Try again!", { transition: Zoom });
        }
      );

    this.resetForm();
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  isFormValid = () => {
    const { name, email, subject, message } = this.state;
    return name && email && subject && message;
  };

  render() {
    const disabled = this.state.email;

    return (
      <>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <Label className="text-muted">Email address</Label>
            <Input
              type="email"
              name="email"
              value={this.state.email}
              className="text-primary"
              onChange={this.handleChange.bind(this, "email")}
              placeholder="Enter email"
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-muted">Name</Label>
            <Input
              type="text"
              name="name"
              value={this.state.name}
              className="text-primary"
              onChange={this.handleChange.bind(this, "name")}
              placeholder="Name"
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-muted">Subject</Label>
            <Input
              type="text"
              name="subject"
              className="text-primary"
              value={this.state.subject}
              onChange={this.handleChange.bind(this, "subject")}
              placeholder="Subject"
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-muted">Message</Label>
            <Input
              type="textarea"
              name="message"
              className="text-primary"
              value={this.state.message}
              onChange={this.handleChange.bind(this, "message")}
            />
          </FormGroup>
          <Button
            variant="primary"
            type="submit"
            disabled={!this.isFormValid()}
          >
            Submit
          </Button>
        </Form>
        <ToastContainer />
      </>
    );
  }
}

export default ContactForm;
