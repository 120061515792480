import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-pdf/dist/Page/AnnotationLayer.css";
import ImageSlider from "../components/imageSlider";

function Restaurant() {
  return (
    <div>
      <h1 className="title" style={{ fontSize: "70px" }}>
        Cafe 7 Days
      </h1>
      <div>
        <button
          type="button"
          class="btn btn-warning"
          style={{ marginRight: 20 }}
        >
          <h3>
            <a href="#menu">Menu</a>
          </h3>
        </button>
        <button type="button" class="btn btn-warning">
          <h3>
            <a href="#picture">Picture</a>
          </h3>
        </button>
      </div>
      <div className="col">
        <div
          id="menu"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <iframe
            src="https://docdro.id/IHNQnUc"
            width="500"
            height="650"
          ></iframe>
        </div>
      </div>
      <div
        className="col"
        id="picture"
        style={{ display: "flex", justifyContent: "center", marginBottom: 30 }}
      >
        <ImageSlider />
      </div>
    </div>
  );
}

export default Restaurant;
