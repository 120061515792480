import React, { useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

function Map() {
  const [selectedPark, setSelectedPark] = useState(null);

  const detailInfo = {
    location: {
      lat: 53.7594,
      lng: -112.784124,
    },
    name: "7 Days Hotel / Cafe 7 Days",
    address: "4815 51 St, Lamont, AB T0B 2R0",
    phone: "780-8952413",
    email: "kim@7daysinn.ca",
  };
  return (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: 53.759437, lng: -112.78378 }}
    >
      <Marker
        position={{
          lat: detailInfo.location.lat,
          lng: detailInfo.location.lng,
        }}
        onClick={() => {
          setSelectedPark(detailInfo);
        }}
      />

      {selectedPark && (
        <InfoWindow
          position={{
            lat: selectedPark.location.lat,
            lng: selectedPark.location.lng,
          }}
          onCloseClick={() => {
            setSelectedPark(null);
          }}
        >
          <div>
            <h2>{selectedPark.name}</h2>
            <p>{selectedPark.address}</p>
            <p>{selectedPark.phone}</p>
            <p>{selectedPark.email}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default WrappedMap;
