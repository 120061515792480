import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

class ImageSlider extends React.Component {
  importAll(r) {
    return r.keys().map(r);
  }

  getImage() {
    let images = [];
    this.importAll(
      require.context("../images/restaurant/", false, /\.(png|jpeg|svg)$/)
    ).map((img) => {
      images.push({ url: img });
    });

    return images;
  }

  render() {
    const images = this.getImage();

    return (
      <div>
        <SimpleImageSlider width={900} height={700} images={images} />
      </div>
    );
  }
}

export default ImageSlider;
