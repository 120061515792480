import React from "react";

const importAll = (r) => {
  return r.keys().map(r);
};

const getImage = () => {
  let images = [];
  importAll(
    require.context("../images/restaurant/", false, /\.(png|jpeg|svg)$/)
  ).map((img) => {
    images.push({ src: img, width: 1, height: 1 });
  });
  return images;
};

export const photos = [...getImage()];
